  
@import '../../../base/assets/scss/_base/config';

.m-dispatcher--rightrail {
  margin: 0 -10px 20px -10px;
  padding: 0;
  position: relative;
  text-align: center;
  z-index: 12;

  @media screen and (max-width: 480px) {
    padding-top: 60px;
  }
}